import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MainPage.css";

function MainPage() {
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Function to retrieve the username from the JWT token
    const getUsernameFromToken = () => {
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in local storage

      // Decode the JWT token to extract user information
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the payload
        setUsername(decodedToken.username); // Extract the username from the decoded token
      }
    };

    getUsernameFromToken();
  }, []);

  const handleLogout = async () => {
    try {
      // Make a POST request to the logout endpoint
      const response = await fetch("https://rotaractsfsu.online:3000/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        // Clear the JWT token from local storage
        localStorage.removeItem("token");
        // Redirect the user to the login page
        navigate("/");
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    // Function to authenticate token on component mount
    const authenticateToken = async () => {
      try {
        const response = await fetch(
          "https://rotaractsfsu.online:3000/auth-check",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 401) {
          // Unauthorized, clear token and redirect to login page
          localStorage.removeItem("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
      }
    };

    authenticateToken(); // Call the authentication function on component mount
  }, [navigate]);

  const handleTakePicture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoElement = document.createElement("video");
      videoElement.srcObject = stream;
      videoElement.play();
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL("image/png");
      // Handle the captured image data here, e.g., upload to server
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    // Handle the uploaded file here, you can send it to a server or process it further
  };

  return (
    <div className="main-page">
      <header>
        <h1>Hello {username}</h1>
        <h1>Welcome to Rotaract at SFSU Scavenger Hunt!</h1>
        <nav>
          <ul>
            <li>
              <a href="#riddles">Riddles</a>
            </li>
            <li>
              <a href="#tasks">Tasks</a>
            </li>
            <li>
              <a href="#map">Map</a>
            </li>
            <li>
              <a href="#leaderboard">Leaderboard</a>
            </li>
          </ul>
        </nav>
        <button onClick={handleLogout}>Logout</button>
      </header>
      <main>
        <section id="riddles">
          <h2>Riddles</h2>
          <div className="riddles-container">
            <button onClick={handleTakePicture}>Take Picture</button>
          </div>
        </section>
        <section id="tasks">
          <h2>Tasks</h2>
          <div className="tasks-container">{/* Add your tasks here */}</div>
        </section>
        <section id="map">
          <h2>Map</h2>
          <div className="map-container">{/* Add your map here */}</div>
        </section>
        <section id="leaderboard">
          <h2>Leaderboard</h2>
          <div className="leaderboard-container">
            {/* Add your leaderboard here */}
          </div>
        </section>
      </main>
    </div>
  );
}

export default MainPage;
